import React from 'react';
import PsychiatryImage from './images/unitedexample.png';
import PsychiatryImage1 from './images/optumexample.png';
import PsychiatryImage2 from './images/oscarexample.png';
import PsychiatryImage3 from './images/molinaexample.png';
import PsychiatryImage4 from './images/humanaexample.png';
import PsychiatryImage5 from './images/sunshineexample.png';
import PsychiatryImage6 from './images/aetnaexample.png';
import PsychiatryImage7 from './images/floridaexample.png';
import PsychiatryImage8 from './images/cignaexample.png';
import PsychiatryImage9 from './images/ambetterexample.png';
import PsychiatryImage10 from './images/carelonexample.png';
import PsychiatryImage11 from './images/medicaidexample.png';
const OverlayComponent = () => {
    const styles = {
        overlay: {
            position: 'relative',
            bottom: '20px',
            left: '15%',
            width: '70%',
            height: '40%',
            background: 'linear-gradient(to bottom, White 90%, #dedede 90%)',
            borderRadius: '8px',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '5% 5%',
            boxSizing: 'border-box',
        },
        button: {
            backgroundColor: 'transparent', // Make button background transparent
            border: 'none',
            cursor: 'pointer',
            width: '100%',
            height: '100%', // Height adjusted to fill the grid cell
            boxSizing: 'border-box',
            position: 'relative',
            overflow: 'hidden',
        },
        img: {
            width: '100%',
            height: '100%',
            objectFit: 'contain', // Ensure the image fits within the button without stretching
        },
        gridContainer: {
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gridTemplateRows: 'repeat(4, 1fr)',
            gap: '10%', // Adjust the gap between rows and columns
            width: '100%',
            height: '100%',
            alignItems: 'center',
            justifyContent: 'center',
        },
        fixedBox: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            width: '100%',
            height: '90%',
            paddingBottom: '15%', // This creates an aspect ratio of 4:3
            position: 'relative',
            backgroundColor: 'white',
            borderRadius: '8px',
            overflow: 'hidden',
        },
        disclaimer: {
            color: 'gray',
            fontFamily: 'Arial, sans-serif',
            fontSize: '1vw',
            margin: '0',
            textAlign: 'left',
            width: '100%',
            position: 'absolute',
            bottom: '3%',
            left: '5%',
        },
    };

    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };

    const buttons = [
        { label: 'Link 1', url: 'https://www.uhc.com/', imgSrc: PsychiatryImage },
        { label: 'Link 2', url: 'https://www.optum.com/en/care/locations/florida/optum-florida.html', imgSrc: PsychiatryImage1 },
        { label: 'Link 3', url: 'https://www.hioscar.com/', imgSrc: PsychiatryImage2 },
        { label: 'Link 4', url: 'https://www.molinahealthcare.com/', imgSrc: PsychiatryImage3 },
        { label: 'Link 5', url: 'https://www.humana.com/', imgSrc: PsychiatryImage4 },
        { label: 'Link 6', url: 'https://www.sunshinehealth.com/', imgSrc: PsychiatryImage5 },
        { label: 'Link 7', url: 'https://www.aetna.com/', imgSrc: PsychiatryImage6 },
        { label: 'Link 8', url: 'https://www.floridablue.com/', imgSrc: PsychiatryImage7 },
        { label: 'Link 9', url: 'https://www.cigna.com/', imgSrc: PsychiatryImage8 },
        { label: 'Link 10', url: 'https://www.ambetterhealth.com/', imgSrc: PsychiatryImage9 },
        { label: 'Link 11', url: 'https://www.carelon.com/', imgSrc: PsychiatryImage10 },
        { label: 'Link 12', url: 'https://www.medicaid.gov/', imgSrc: PsychiatryImage11 },
    ];

    return (
        <div style={styles.overlay}>
            <div style={styles.fixedBox}>
                <div style={styles.gridContainer}>
                    {buttons.map((button, index) => (
                        <a key={index} href={button.url} target="_blank" rel="noopener noreferrer" style={styles.button}>
                            {button.imgSrc && <img src={button.imgSrc} alt={button.label} style={styles.img} />}
                        </a>
                    ))}
                </div>
            </div>
            <p style={styles.disclaimer}>Disclaimer: Understanding Insurance Coverage for Psychiatry Services</p>
        </div>
    );
};

export default OverlayComponent;
