import React from 'react';

const Footer = () => {
    return (
        <footer style={styles.footer}>
            <div style={styles.container}>
                <p style={styles.text}>© 2024 Tampa Bay Mental Health LLC. All rights reserved.</p>
                <img src={require('./images/seal.gif')} alt="Company Logo" style={styles.logo} />
            </div>
        </footer>
    );
};

const styles = {
    footer: {
        backgroundColor: 'rgb(64, 64, 64)',
        color: 'white',
        textAlign: 'center',
        padding: '20px 0',
    },
    container: {
        maxWidth: '900px',
        margin: '0 auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        
    },
    text: {
        fontSize: '0.8vw',
        fontFamily: 'Arial, sans-serif',
        marginRight: '20px', 
    },
    logo: {
        width: '7vw',
        height: 'auto',
    },
};

export default Footer;
