import React from 'react';
import skylineImage from './images/skylineTrans.jpeg'; // Import the image
import './Bio.css';
import Footer from './copyright'; // Import the Footer component
import Bottom from './bottom';

const Bio = () => {
  return (
    <div>
      <div className="bio-container">
        <div className="banner" style={{ height: '20vw', position: 'relative' }}> {/* Set the height of the banner to 50% of the viewport height */}
          <img src={skylineImage} alt="Banner Image" style={{ opacity: 0.4, width: '100%', height: '100%', objectFit: 'cover' }} />
          <div className="banner-text">
            <div className="title">Dr. Orlando L. Ruano, DO</div>
            <div className="subtitle">Medical Director</div>
          </div>
        </div>
        <div className="content" style={{ position: 'relative' }}>
          <h1 style={{ fontSize: '48px', marginBottom: '20px', color: '#b5b4b3', fontFamily: 'goldenbook, serif', marginTop: '0' }}></h1>
          <img src={'./ruanopersonal.png'} alt="Headshots Image" style={{ width: '30vw', height: 'auto', marginTop: '7%' , left: '0', marginLeft: '7%', position: 'absolute', top: '0', border: 'none' }} />
          {/* Additional content goes here */}
        </div>
      </div>
      <Bottom/>
      <Footer /> {/* Include the Footer component here */}
    </div>
  );
};

export default Bio;
