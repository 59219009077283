import React from 'react';
import './Linebaugh.css'; // Assuming you have a CSS file for styling
import MyApp from './bookingcomp';
import Particlebg from './Particles';

function App() {
  return (
    <div className="windowContainer">

      <MyApp>
        
    
     
      </MyApp>
     
      
      
    </div>
  );
}

function BlueSquare() {
  return (
    <div className="blueSquare"></div>
  );
}

export default App;
