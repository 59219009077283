import React from 'react';
import skylineImage from './images/skylineTrans.jpeg'; // Import the image
import './Bio.css';
import Footer from './copyright'; // Import the Footer componenti

const Bio = () => {
  return (
    <div>
      <div className="bio-container">
        <div className="banner">
          <img src={skylineImage} alt="Banner Image" style={{ opacity: 0.4, width: '100%', height: '100%', objectFit: 'cover' }} />
          <div className="banner-text">
            <div className="title">Stefanny Molina Mesa, APRN</div>
            <div className="subtitle">Adult and Child Psychiatry</div>
          </div>
        </div>
        <div className="content">
          <h1 style={{ fontSize: '48px', marginBottom: '20px', color: '#b5b4b3', fontFamily: 'goldenbook, serif', marginTop: '0' }}>Title</h1>
          {}
        </div>
      </div>
      <Footer /> {}
    </div>
  );
};

export default Bio;
