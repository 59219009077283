import React, { useState, useEffect } from 'react';
import OverlayComponent from './OverLayComponent';
import Bottom from './bottom';
import ClimbingBoxLoader from 'react-spinners/HashLoader.js';
import Footer from './copyright.jsx';

const CheckInsurance = () => {
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        
        const timeout = setTimeout(() => {
            setLoading(false); 
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    const stylePicture = {
        container: {
            backgroundColor: '#F9F9F9',
            width: "100vw", 
            height: "100%", 
        },
        image: {
            width: '100%',
            height: '100%',
        },
        button: {
            position: 'absolute',
            bottom: '20px', 
            left: '50%', 
            transform: 'translateX(-50%)', 
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '20px',
            cursor: 'pointer',
            fontSize: '16px',
        },
    };

    return (
        <div style={stylePicture.container}>
            {loading ? ( 
                <div className="loading-overlay">
                    <ClimbingBoxLoader
                        color="red"
                        loading={loading}
                        size={135}
                        aria-label="Loading Spinner"
                    />
                </div>
            ) : (
                <React.Fragment>
                    <img src={require("./images/SmilingFinal.png")} alt="Description of the image" style={stylePicture.image} />
                    <OverlayComponent />
                    
                    
                    {/* Other content */}
                </React.Fragment>
            )}
            <Bottom />
            <Footer />
        </div>
    );
};

export default CheckInsurance;
