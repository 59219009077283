import React, { useState, useEffect, useRef } from 'react';
import { BrowserRouter as Router, HashRouter, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Header from './Header';
import Bottom from './bottom.js';
import Checkinsurance from './Checkinsurance.jsx';

import SearchableList from './list.js';
import Teammem1 from './teammem1.jsx';
import Teammem2 from './Teammem2.jsx';
import Teammem3 from './Teammem3.jsx';
import Teammem4 from './Teamem4.jsx';
import './skyline.css';
import Bookapt from './bookapt.jsx';
import Navbar from './navbar.js';
import Loc1 from './Linebaugh.jsx';
import Loc2 from './Linebaugh.jsx';
import Treatment2 from './treatment2.jsx';
import Treatment3 from './treatment3.jsx';
import Treatment4 from './Treatment4.jsx';
import bannerImage from './images/skylineFinal.jpeg';
import TeamSection from './Teamsec.js';
import Bio from './Bio.jsx';
import MobilePopup from './mobilepopup.js';
import Thanks from './thanks.jsx';
import ClimbingBoxLoader from 'react-spinners/HashLoader.js';
import Footer from './copyright.jsx';
import ScrollToTopOnLinkClick from './scrollToTop.jsx'; 
function App() {
  const [showSlogan, setShowSlogan] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [loading, setLoading] = useState(false); 
  const [currentPath, setCurrentPath] = useState(window.location.pathname);
  
  const typewriterRef = useRef(null); // Create a reference for the typewriter div
  
  let typingTimeout;

  // Array of sentences for the typing animation
  const slogans = [
    " Reliable, Affordable, Help. ",
    " Professional and Caring Support. ",
    " Your Mental Health Matters. ",
    " We're Here for You. "
  ];

  let currentIndex = 0;

  useEffect(() => {
    setLoading(true);

    const timeout = setTimeout(() => {
      setLoading(false); 
      setShowSlogan(true); // Show the slogan after loading completes
    }, 3000);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    handleResize(); 
    window.addEventListener('resize', handleResize);

    const handleRouteChange = () => {
      setCurrentPath(window.location.pathname);
      if (window.location.pathname !== "/") {
        setShowSlogan(false); // Hide the slogan when navigating away
      }
    };

    window.addEventListener('popstate', handleRouteChange);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('popstate', handleRouteChange);
    };
  }, [currentPath]);

  // Re-trigger the typewriter when navigating back to the main page
  useEffect(() => {
    if (currentPath === "/") {
      setShowSlogan(false); // Reset slogan
      setTimeout(() => {
        setShowSlogan(true); // Re-show the slogan after delay
      }, 100); // Delay to ensure smooth re-render
    }
  }, [currentPath]);

  // Typewriter effect logic using useRef
  useEffect(() => {
    if (showSlogan && typewriterRef.current) {
      const typingSpeed = 100;
      const deletingSpeed = 50;
      const pauseBetween = 700;

      let index = 0;
      let isDeleting = false;

      function type() {
        const typewriter = typewriterRef.current;
        const text = slogans[currentIndex];

        if (typewriter) {
          let displayedText = text.substring(0, index);
          typewriter.innerHTML = displayedText;

          if (!isDeleting && index < text.length) {
            index++;
            typingTimeout = setTimeout(type, typingSpeed);
          } else if (isDeleting && index > 0) {
            index--;
            typingTimeout = setTimeout(type, deletingSpeed);
          } else if (!isDeleting && index === text.length) {
            setTimeout(() => {
              isDeleting = true;
              typingTimeout = setTimeout(type, deletingSpeed);
            }, pauseBetween);
          } else if (isDeleting && index === 0) {
            isDeleting = false;
            currentIndex = (currentIndex + 1) % slogans.length; // Move to next slogan
            typingTimeout = setTimeout(type, pauseBetween);
          }
        }
      }

      type(); // Start typing

      // Cleanup function to clear timeout when unmounted or switching pages
      return () => {
        clearTimeout(typingTimeout);
      };
    }
  }, [showSlogan]); // Ensure effect re-runs when showSlogan changes

  return (
    <div className="App">
      <HashRouter>
        <Navbar />
        <div className="content-wrapper">
          <ScrollToTopOnLinkClick />
          <Routes>
            <Route path="/Teammem1" element={<Teammem1 />} />
            <Route path="/Teammem2" element={<Teammem2 />} />
            <Route path="/Teammem3" element={<Teammem3 />} />
            <Route path="/Teammem4" element={<Teammem4 />} />
            <Route path="/bookapt" element={<Bookapt />} />
            <Route path="/location1" element={<Loc1 />} />
            <Route path="/location2" element={<Loc2 />} />
            <Route path="/treatments" element={<Treatment3 />} />
            <Route path="/treatment1" element={<Treatment3 />} />
            <Route path="/Meet" element={<Bio />} />
            <Route path="/treatment2" element={<Treatment2 />} />
            <Route path="/treatment3" element={<Treatment4 />} />
            <Route path="/treatment4" element={<Treatment4 />} />
            <Route path="/checkinsurance" element={<Checkinsurance />} />
            <Route path="/thanks" element={<Thanks />} />
            <Route path="/" element={
              <React.Fragment>
                {isMobile && <MobilePopup />}
                <div className="banner-container">
                  <img src={bannerImage} alt="Banner" className="banner-image" />
                  <div className="banner-text banner-text-animation">Tampa Bay Mental Health</div>
                  <div className={showSlogan ? "slogan show" : "slogan"}>
                    <div id="typewriter" ref={typewriterRef}></div> {/* Use ref here */}
                    <div className="button-container"></div>
                  </div>
                </div>
                <TeamSection />
                <Bottom />
                <Footer />
              </React.Fragment>
            } />
          </Routes>
        </div>
      </HashRouter>
      {loading && (
        <div className="loading-overlay">
          <ClimbingBoxLoader
            color="red"  
            loading={loading}
            size={135}
            aria-label="Loading Spinner"
          />
        </div>
      )}
    </div>
  );
}

export default App;