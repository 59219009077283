import React from 'react';
import ClimbingBoxLoader from 'react-spinners/HashLoader.js';

const LoadingOverlay = ({ loading }) => {
  return (
    loading && (
      <div className="loading-overlay">
        <ClimbingBoxLoader
          color="#ff0f03"  
          loading={loading}
          size={135}
          aria-label="Loading Spinner"
        />
      </div>
    )
  );
}

export default LoadingOverlay;

