import React, { useState, useEffect } from 'react';
import PsychiatryImage from './Media/gr1p.png'; // Import your image file
import './Treatment4.css'; // Import CSS file for styling
import SecondSection from './T4secondsection'; // Import SecondSection component
import LoadingOverlay from './LoadingOverlay'; // Import the LoadingOverlay component

const Treatment4 = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading delay
    const timeout = setTimeout(() => {
      setLoading(false); // Set loading to false after delay
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div>
      {/* Loading overlay */}
      <LoadingOverlay loading={loading} />

      {/* First Section */}
      <div className="video-container" style={{ position: 'relative', overflow: 'hidden', backgroundColor: '#f0f0f0' }}>
        {/* Image */}
        <h1 className="video-text">Group Therapy</h1>
        <img src={PsychiatryImage} alt="Group Therapy" className="fullscreen-video" />
      </div>

      {/* Second Section */}
      <SecondSection />
    </div>
  );
};

export default Treatment4;
