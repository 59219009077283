import React from 'react';
import './T3secondsection.css'; // Import CSS file for styling
import BrainswayImage from './images/Brainsway.webp'; // Import image
import H1Coil from './images/H1Coil.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import Bottom from './bottom.js';

const SecondSection = () => {
  return (
    <div className="second-section">
      <div className="text-box">
        <p className="title">Enter a Revolutionary Phase in Brain Disorder Treatment.</p>
        <p className="subtext">Tampa Bay Mental Health is proud to offer the latest breakthrough technology in brain stimulation treatments, Deep Transcranial Magnetic Stimulation (Deep TMS). Our BrainsWay Deep TMS machine is a clinically proven, non-invasive brain stimulation treatment which uses magnetic fields to activate the neural networks in your brain. Deep TMS has shown to improve symptoms of mental illness and addiction conditions, including depression, anxiety, OCD, and smoking addiction.</p>
        <img src={BrainswayImage} alt="Brainsway" className="brainsway-image" />
      </div>
            
      <div className="text-box2">
        <p className="titleblue">H1</p>
        <p className="title2">Major Depressive Disorder (MDD) and Anxious Depression</p>
        <p className="subtext2">Our Tampa psychiatry office currently offers the H1 Coil TMS treatment. The H1 Coil stimulates the bilateral prefrontal cortex, and in numerous published trials has shown promising results. Out of 1,000 patients in a clinical setting, it was revealed in that patients who completed at least 30 sessions, approximately 4 in 5 achieved response and approximately 2 in 3 achieved remission. - Info via brainsway.com</p>
        <img src={H1Coil} alt="CoilImage" className="coil-image"/>
      </div>

      <div className="text-box3">
        <p className="title3"></p>
        <iframe 
          width="100%" 
          height="500" 
          src="https://www.youtube-nocookie.com/embed/N182lACt7ts?si=9k9rVs8o7ttOeiFR" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" 
          referrerPolicy="strict-origin-when-cross-origin" 
          allowFullScreen>
        </iframe>

      </div>
      
    </div>
  );
};

export default SecondSection;
